import {createTheme, Theme} from "@mui/material/styles";
import {teal} from "@mui/material/colors";

const theme: Theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: "rgba(0,0,0,1)",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                },
            }
        },
    },
    palette: {
        mode: "dark",
        primary: teal,
        secondary: teal
    }
});

export default theme;